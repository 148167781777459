import la from '../LazyComponent';

const ClientManagement = la(() => import('@/pages/ClientManagement'));
const ClientInformation = la(() => import('@/pages/ClientInformation'));
const AccountDetail = la(() => import('@/pages/AccountDetail'));
const ClientDetails = la(() => import('@/pages/ClientDetails'));

const ClientManagementRoutes = [
  {
    path: '/clients',
    element: ClientManagement,
    meta: {
      key: 'clients',
    },
  },
  {
    path: '/clients/information/:id',
    element: ClientInformation,
    meta: {
      key: 'clients',
    },
  },
  {
    path: '/clients/account/detail/:id',
    element: AccountDetail,
    meta: {
      key: 'clients',
      breadList: [
        {
          path: '/clients',
          title: 'menu.Clients', // from language dic
        },
        {
          path: '',
          title: 'clientProfile.accountDetail.title', // from language dic
        },
      ],
    },
  },
  {
    path: '/clients/detail/:id',
    element: ClientDetails,
    meta: {
      key: 'clients',
    },
  },
];
export default ClientManagementRoutes;
