export const currentEnv = process.env.REACT_APP_ENV;
/**
 * @description request base url
 * leopard
 * tiger
 * whale
 * beaver
 * elephant
 */

// 测试环境地址
// https://sws-beaver.crm-alpha.com/

export const proxyUrl = 'https://sws-beaver.crm-alpha.com/';
