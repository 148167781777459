/*
 * @Description:
 */
import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/common/index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import './lang/config';

const render = () => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    // <React.StrictMode>
    <App />
    // </React.StrictMode>,
  );
};

try {
  const { pathname, search } = window.location;
  console.log(pathname, 'pathname');
  if (pathname === '/') {
    const newPathname = '/salework-station/dashboard';
    window.location.href = `${newPathname}${search || ''}`;
  } else {
    render();
  }
} catch (e) {
  console.error(e);
  render();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.warn);
