import la from '../LazyComponent';

const LeadManagement = la(() => import('@/pages/LeadManagement'));
const LeadManagementRoutes = [
  {
    path: '/leads',
    element: LeadManagement,
    meta: {
      key: 'leads',
    },
  },
];
export default LeadManagementRoutes;
