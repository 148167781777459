export const enum AU_REGULATORS {
  VFSC = 'vfsc',
  VFSC2 = 'vfsc2',
  CIMA = 'cima',
  FCA = 'fca',
  ASIC = 'asic',
}
export const enum PU_REGULATORS {
  SVG = 'svg',
  FSA = 'fsa',
}

export const enum VT_REGULATORS {
  SVG = 'svg',
}

export const BREAD_INDEX = {
  au: 'AU',
  pug: 'PUG',
  vt: 'VT',
};

// favicon
// logo
export const BRAND_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/logo.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/logo.png'),
};
// small logo
export const BRAND_CONSTRICT_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/logo-constrict.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/logo-constrict.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/logo-constrict.png'),
};
// login logo
export const BRAND_LOGIN_LOGO = {
  [BREAD_INDEX.au]: require('@/assets/au/sws-login-logo.png'),
  [BREAD_INDEX.pug]: require('@/assets/pug/sws-login-logo.png'),
  [BREAD_INDEX.vt]: require('@/assets/vt/sws-login-logo.png'),
};
// regulator list
export const BRAND_REGULATOR = {
  [BREAD_INDEX.au]: [
    { label: 'VFSC', value: AU_REGULATORS.VFSC },
    { label: 'VFSC2', value: AU_REGULATORS.VFSC2 },
    { label: 'CIMA', value: AU_REGULATORS.CIMA },
    { label: 'FCA', value: AU_REGULATORS.FCA },
    { label: 'ASIC', value: AU_REGULATORS.ASIC },
  ],
  [BREAD_INDEX.pug]: [
    { label: 'SVG', value: PU_REGULATORS.SVG },
    { label: 'FSA', value: PU_REGULATORS.FSA },
  ],
  [BREAD_INDEX.vt]: [{ label: 'SVG', value: PU_REGULATORS.SVG }],
};

// video for tutorial
export const BRAND_VIDEO = {
  [BREAD_INDEX.au]: require('@/assets/videos/video-AU.mp4'),
  [BREAD_INDEX.pug]: require('@/assets/videos/video-PU.mp4'),
  [BREAD_INDEX.vt]: require('@/assets/videos/video-VT.mp4'),
};
