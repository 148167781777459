/**
 * @description 全局loading
 */
import React from 'react';
import { Spin } from 'antd';
import s from './index.module.less';

interface PropsType {
  size?: 'big' | 'middle' | 'small';
}

function PageLoading(props: PropsType) {
  const { size = 'big' } = props;
  return (
    <div
      className={
        size === 'big' ? s.loadWrap : size === 'middle' ? s.loadWrapMiddle : s.loadWrapSmall
      }
    >
      <Spin tip="Loading" size="large" />
    </div>
  );
}

export default PageLoading;
