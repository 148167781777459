import la from '../LazyComponent';

const Messages = la(() => import('@/pages/Messages'));

const MessagesRoutes = [
  {
    path: '/messages',
    element: Messages,
    meta: {
      key: 'messages',
    },
  },
];

export default MessagesRoutes;
