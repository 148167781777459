import {
  BRAND_LOGO,
  BRAND_CONSTRICT_LOGO,
  BRAND_LOGIN_LOGO,
  BRAND_REGULATOR,
  BREAD_INDEX,
  BRAND_VIDEO,
} from '@/constant/brand';

const useBrandHandler = () => {
  const envBrand = process.env.CURRENT_BRAND;
  const CURRENT_BRAND = envBrand?.replaceAll('"', '') || '';

  const isPug: boolean = CURRENT_BRAND === BREAD_INDEX.pug;

  const isAu: boolean = CURRENT_BRAND === BREAD_INDEX.au;

  const isVt: boolean = CURRENT_BRAND === BREAD_INDEX.vt;

  const getLogo = () => {
    return BRAND_LOGO[CURRENT_BRAND] || '';
  };

  const getConstrictLogo = () => {
    return BRAND_CONSTRICT_LOGO[CURRENT_BRAND] || '';
  };

  const getLoginLogo = () => {
    return BRAND_LOGIN_LOGO[CURRENT_BRAND] || '';
  };

  const getRegulatorList = () => {
    return BRAND_REGULATOR[CURRENT_BRAND] || [];
  };

  const getBrandVideoUrl = () => {
    return BRAND_VIDEO[CURRENT_BRAND] || '';
  };

  return {
    CURRENT_BRAND,
    getLogo,
    getConstrictLogo,
    getLoginLogo,
    getRegulatorList,
    getBrandVideoUrl,
    isPug,
    isAu,
    isVt,
  };
};

export default useBrandHandler;
