import la from '../LazyComponent';

const SalesWorkStation = la(() => import('@/pages/SalesWorkStation'));
const RingCentralCallback = la(() => import('@/pages/RingCentralCallback'));

const SalesWorkStationRoutes = [
  {
    index: true,
    path: '/salework-station/dashboard',
    element: SalesWorkStation,
    meta: {
      key: 'sales',
    },
  },
  {
    index: true,
    path: '/ring-central/callback',
    element: RingCentralCallback,
    meta: {
      key: 'sales',
    },
  },
];
export default SalesWorkStationRoutes;
